<template>
  <form class="mx-form mx-form-auth" @submit.prevent="login">
    <div class="mb-3">
      <label class="form-label">{{ username }}</label>
    </div>
    <div class="mb-3">
      <label class="form-label">{{ $t('auth.form.login.label.password') }}</label>
      <input type="password" class="form-control" v-model="form.password">
      <div v-if="errors.password" class="invalid-feedback">
        {{ $t('auth.form.login.required.password') }}
      </div>
    </div>
    <button :disabled="preloader" type="button" class="btn btn-peepz-accept" @click.prevent="login">{{ $t('auth.login') }}</button>
    <div v-if="errors.error" class="invalid-feedback">
      {{ $t('auth.form.login.error') }}
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      username: sessionStorage.getItem('username'),
      form: {
        email: sessionStorage.getItem('email'),
        password: ''
      },
      errors: {},
      preloader: false
    }
  },
  methods: {
    login() {
      this.preloader = true
      this.$store.dispatch('signIn', this.form).then(() => {
        this.$router.replace({
          name: 'dashboard'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.error;
            break;
        }
        this.preloader = false
      })
    }
  }
}
</script>
